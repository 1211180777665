export default () => ({
  isOpen: false,
  saving: false,
  elementId: null,
  checkedLists: [],
  options: null,
  fields: null,
  init() {
    this.getWishlistState()

    let searchParams = new URLSearchParams(window.location.search);
    let savePayload = localStorage.getItem('savePayload')
    if(savePayload && searchParams.has('fromsave')){
      setTimeout(() => {
        let e = {
          detail: JSON.parse(savePayload)
        }
        this.open(e)
        localStorage.removeItem('savePayload')
      }, 300)

    }

  },
  async getWishlistState() {
    await fetch('/?action=expansion/wishlist/get-state', {
      method: 'get',
      headers: {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    }).then((response) => {
      response.json().then((data) => {
        this.projects = data.projects
      })
    })
  },
  open(e) {
    if (!this.currentUser) {
      let hash = window.location.hash.substring(1)
      if (hash) {
        localStorage.setItem('saveHash', hash)
      }
      if (window.location.search) {
        localStorage.setItem('saveQueryString', window.location.search)
      }
      localStorage.setItem('savePayload', JSON.stringify(e.detail))
      window.location = '/login?redirect=' + encodeURIComponent(window.location.pathname) + '&hash=' + hash
      return
    }

    this.elementId = e.detail.elementId
    this.options = e.detail.options ?? [],
    this.fields = e.detail.fields ?? [],
    this.checkedLists = e.detail.customized ? [] : this.projects.filter((p) => p.items?.includes(this.elementId)).map((p) => p.id)
    this.isOpen = true
  },
  close() {
    this.saving = false
    this.isOpen = false
  },
  remove(e) {
    const listId = e.detail.listId
    const formData = new FormData()
    formData.append(csrfTokenName, csrfTokenValue)
    formData.append('action', 'expansion/wishlist-items/remove')
    if(e.detail.elementId){
      formData.append('elementId', e.detail.elementId)
    }
    if(e.detail.itemId){
      formData.append('itemId', e.detail.itemId)
    }
    formData.append('listId', listId)
    fetch('/', {
      method: 'post',
      body: formData,
      headers: {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    }).then((response) => {
      response.json().then((data) => {
        this.projects = data.projects.projects

        window.dispatchEvent(new CustomEvent('notify', {
          detail: {
            id: new Date().getTime(),
            content: data.success ? 'Item removed' : 'Error removing product',
            type: data.success ? 'success' : 'error',
          }
        }));
        // reload page after 1 second
        setTimeout(() => {
          location.reload()
        }, 500)
      })
    })
  },
  get projectsToDisplay() {
    if (!this.projects) return
    const hasFavourite =
      this.projects.filter((p) => p.type == 'favorites').length > 0
    return hasFavourite
      ? this.projects
      : [
          {
            id: 0,
            title: 'Favorites',
            ref: 'favorites',
            type: 'favorites',
          },
          ...this.projects,
        ]
  },
  newProject() {
    const title = prompt('Enter a project name', 'My new project')
    if (title) {
      const formData = new FormData()
      formData.append(csrfTokenName, csrfTokenValue)
      formData.append('action', 'wishlist/lists/create')
      formData.append('title', title)
      formData.append('typeId', 1)

      fetch('/', {
        method: 'post',
        body: formData,
        headers: {
          Accept: 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
        },
      }).then((response) => {
        response.json().then((data) => {
          this.projects.push({
            id: data.id,
            title: data.title,
            ref: data.reference,
            type: 'projects',
          })
          this.checkedLists.push(data.id)
          return false
        })
      })
    }
  },
  save() {
    this.saving = true
    const formData = new FormData()
    formData.append(csrfTokenName, csrfTokenValue)
    formData.append('action', 'expansion/wishlist/save-multiple')
    this.projectsToDisplay.map((l) => {
      formData.append(
        'listIds[' + l.id + ']',
        this.checkedLists.includes(l.id) ? 1 : 0,
      )
    })
    // this.options?.map((o) => {
    //   formData.append(`options[${o.option}]`, o.value)
    // })
    if(this.options) {
      Object.keys(this.options).map((o) => {
        formData.append(`options[${o}]`, this.options[o])
      })
    }
    const fieldsArray = Object.keys(this.fields)
    if (fieldsArray|length) {
      fieldsArray.map((f) => {
        formData.append(`fields[${f}]`, this.fields[f])
      })
    }
    formData.append('elementId', this.elementId)
    formData.append('fields[quantity]', 1)

    fetch('/', {
      method: 'post',
      body: formData,
      headers: {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    }).then((response) => {
      response.json().then((data) => {
        this.projects = data.projects
        this.close()
        window.dispatchEvent(new CustomEvent('notify', {
          detail: {
            id: new Date().getTime(),
            content: data.success ? 'Project Updated' : 'Error updating project',
            type: data.success ? 'success' : 'error',
          }
        }));
      })
    })
  },
  saveAsGuest() {
    const action = this.projects
      .map((w) => w.items)
      .flat()
      .includes(this.elementId)
      ? 'remove'
      : 'add'

    const formData = new FormData()
    formData.append(csrfTokenName, csrfTokenValue)
    formData.append('action', 'expansion/wishlist-items/' + action)
    formData.append('elementId', this.elementId)

    fetch('/', {
      method: 'post',
      body: formData,
      headers: {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    }).then((response) => {
      if (!this.projects.length) {
        this.projects.push({
          id: 0,
          title: 'temp',
          ref: 'temp',
          type: 'projects',
          items: [this.elementId],
        })
      } else {
        response.json().then((data) => {
          this.projects = data.projects.projects
        })
      }
    })
  },
})
