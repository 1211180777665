document.documentElement.className = document.documentElement.className.replace(
  'no-js',
  'js',
)

import LazyLoad from 'vanilla-lazyload'
import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'
import intersect from '@alpinejs/intersect'
import collapse from '@alpinejs/collapse'
import persist from '@alpinejs/persist'
import {
  AlgoliaProductSearch,
  AlgoliaSearchPage,
  AlgoliaResourceSearch,
  AlgoliaNavigationSearch,
  AlgoliaIdeasFilter,
} from './js/algolia'

import { CylindoViewer } from './js/cylindo'
import { PandiwebViewer } from './js/pandiweb'

import Wishlist from './js/wishlist'

window.siteLazyLoad = new LazyLoad({
  // Your custom settings go here
});

window.getGatedResourceRedirect = (downloadUrl, downloadFilename, hash) => {
  let loc = window.location;
  let redirectUrl = loc.origin + loc.pathname;

  if(loc.search.length){
    redirectUrl = `${redirectUrl}${loc.search}`
    redirectUrl = `${redirectUrl}&downloadUrl=${downloadUrl}&downloadFilename=${downloadFilename}`;
    if(hash){
      redirectUrl = `${redirectUrl}&hash=${hash}`
    }
  } else {
    redirectUrl = `${redirectUrl}?downloadUrl=${downloadUrl}&downloadFilename=${downloadFilename}`;
    if(hash){
      redirectUrl = `${redirectUrl}&hash=${hash}`
    }
  }
  return encodeURIComponent(redirectUrl)
}

Alpine.data('wishlist', Wishlist)

document.addEventListener('alpine:init', () => {
  Alpine.bind('wishlistTrigger', (elementId, options, fields = {}, customized = false) => ({
    type: 'button',
    '@click.prevent'() {
      this.$dispatch('open-wishlist', { elementId, options, fields, customized })
    },
    ':class'() {
      if (customized) return
      return (
        this.projects
          .map((w) => w.items)
          .flat()
          .includes(elementId) && 'is-saved'
      )
    },
  }))
  Alpine.bind('wishlistElement', (elementId, ref, customized = false) => ({
    ':class'() {
      if (!this.projects) return
      // if (customized) return
      let project = this.projects.find((project) => {
        return (ref == "favorites") ? project.type == ref : project.ref == ref
      })
      if (!project) return
      return (project.items?.includes(elementId) || project.specs?.includes(elementId)) || 'hidden'
    },
  }))
  Alpine.bind('wishlistRemove', ({elementId, listId, itemId}) => ({
    type: 'button',
    '@click'() {
      this.$dispatch('open-modal', {
        contents: {
            heading: 'Remove item',
            body: 'Are you sure you wish to remove this item from the project?',
            button: 'Remove',
        },
        action: {
            event:'remove-wishlist',
            data: {
              elementId, listId, itemId,
            },
        }
      })
    },
  }))

  setTimeout(() => {
    let searchParams = new URLSearchParams(window.location.search);
    if(searchParams.has('hash')){
      let hash = searchParams.get('hash');
      if(document.getElementById(hash)){
        let hashEl = document.getElementById(hash);
        hashEl.scrollIntoView({ behavior: 'instant' });
      }
    }
  }, 200)

})

import './main.css'

window.Alpine = Alpine

Alpine.plugin(focus)
Alpine.plugin(intersect)
Alpine.plugin(collapse)
Alpine.plugin(persist)
Alpine.data('cylindoViewer', CylindoViewer)
Alpine.data('pandiwebViewer', PandiwebViewer)
Alpine.data('algoliaProductSearch', AlgoliaProductSearch)
Alpine.data('algoliaSiteSearch', AlgoliaSearchPage)
Alpine.data('algoliaResourceSearch', AlgoliaResourceSearch)
Alpine.data('algoliaIdeasFilter', AlgoliaIdeasFilter)
Alpine.start()

AlgoliaNavigationSearch()

if (document.querySelector('.splide')) {
  import('./js/splide').then((instance) => {
    instance.init()
  })
}

if(document.querySelector('[data-choices]')) {
  import('./js/choices').then((instance) => {
    instance.init()
  })
}

const automaticallyDownloadFiles = (url, filename) => {
  const anchorElement = document.createElement('a')
  anchorElement.href = url
  anchorElement.download = filename;
  anchorElement.style.display = 'none'
  document.body.appendChild(anchorElement)
  anchorElement.click()
  anchorElement.remove()
  window.URL.revokeObjectURL(url)
}

window.addEventListener('load', () => {
  let searchParams = new URLSearchParams(window.location.search);

  if(searchParams.has('downloadUrl') && window.Alpine.store('user').currentUser){
    let url = searchParams.get('downloadUrl')
    let filename = searchParams.get('downloadFilename')
    automaticallyDownloadFiles(url, filename)

    console.log('deleting params')
    // Get the current URL
    let currentUrl = new URL(window.location.href);
    // Get the search parameters
    let urlSearchParams = currentUrl.searchParams;
    // Remove the query parameters you want to delete
    urlSearchParams.delete('downloadUrl');
    urlSearchParams.delete('downloadFilename');
    urlSearchParams.delete('assetId');
    urlSearchParams.delete('hash');
    // Update the browser's history without reloading the page
    history.replaceState(null, '', currentUrl.pathname + '?' + urlSearchParams.toString() + currentUrl.hash);
  }
})
