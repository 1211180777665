import Choices from 'choices.js'
import 'choices.js/public/assets/styles/choices.css'
import screenfull from 'screenfull';

export const CylindoViewer = () => {

  return {
    buildShareModalOpen: false,
    configuration: null,
    currentView: '360',
    availableFeatures: [],
    availableFeatureCodes: [],
    featuresWithSwatches: [],
    hiddenSwatches: [],
    featuresToHide: [],
    features: null,
    initialFeatures: {},
    featureHash: null,
    disabled: null,
    productId: null,
    thumbnailBarOptions: null,
    debug: false,
    zoom: 1,
    frame: 1,
    fullscreen: false,
    choicesJs: {},
    renderedFrame: '#',
    saveableImage: '',
    polymodel: 'low',
    materialLevelOfDetail: '4',
    cmsPolymodel: 'low',
    cmsMaterialLevelOfDetail: '4',
    cmsMaterialLevelOfDetailMobile: '5',
    isResetting: false,
    modelLoading: false,
    modelPoster: '',
    modelViewerWrapperClasses: null,
    imageDownloadClicks: 1,
    // modelHasUpdated: false,
    init(){
      this.configuration = JSON.parse(this.$refs.twig.dataset.defaultConfiguration)
      this.features = JSON.parse(this.$refs.twig.dataset.defaultFeatures)
      this.initialFeatures = JSON.parse(this.$refs.twig.dataset.defaultFeatures)
      this.cmsMaterialLevelOfDetail = this.$refs.twig.dataset.modelDetailLevel;
      this.cmsMaterialLevelOfDetailMobile = this.$refs.twig.dataset.modelDetailLevelMobile;
      this.materialLevelOfDetail = this.cmsMaterialLevelOfDetail;
      this.cmsPolymodel = this.$refs.twig.dataset.polymodel;
      this.hiddenSwatches = this.$refs.twig.dataset.hiddenSwatches ?? [];
      // get array of keys from this.initialFeautures
      this.availableFeatureCodes = Object.keys(this.initialFeatures);
      this.featuresWithSwatches = this.configuration.features.filter(
          feature => feature.controlsMaterials && this.hiddenSwatches.indexOf(feature.code) === -1
        ).map(feature => feature.code);
      this.disabled = JSON.parse(this.$refs.twig.dataset.defaultDisabled)
      this.productId = this.$refs.twig.dataset.productId

      this.availableFeatures = this.configuration.features

      let qp = new URLSearchParams(window.location.search)
      let savedQS = localStorage.getItem('saveQueryString')
      let savedHash = localStorage.getItem('saveHash')

      if (qp.has('fromsave') && savedQS) {
        qp = new URLSearchParams(savedQS)
        history.replaceState(null, null, savedQS + "&fromsave=true&hash=" + savedHash)
      }

      // Setup query parameters
      if(qp.has(this.productId)){
        let wrapperSection = this.$el.closest('section');
        if(wrapperSection){
          this.selectedId = wrapperSection.dataset.viewerId;
        }
        let qpFeatures = this.parseQueryString(qp.get(this.productId))
        Object.keys(qpFeatures).forEach(key => {
          let fixedKey = key.replace(/_/g, ' ');
          if(this.availableFeatureCodes.indexOf(fixedKey) !== -1){
            this.features[fixedKey] = qpFeatures[key].replace(/_/g, ' ');
            this.initialFeatures[fixedKey] = qpFeatures[key].replace(/_/g, ' ');
          }
        })
        this.evaluateDependencyFeatures();
        this.modelPoster = this.getModelPosterUrl();
      } else {
        this.processHiddenDependencyFeatures();
      }

      // this.initialFeatures = this.features;

      if(this.$refs.viewer.loaded){
        this.thumbnailBarOptions = this.$refs.viewer.items
        this.$refs.viewer.features = {...this.$refs.viewer.features, ...this.features}
        this.zoom = this.$refs.viewer.zoom

        Object.keys(this.features).forEach(key => {
          if (this.$refs.viewer.features[key] !== undefined){
            this.$refs.viewer.features[key] = this.features[key]
          }
        })
        this.generateStaticImageUrl()
        this.modelPoster = this.getModelPosterUrl();
      }

      if(!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.materialLevelOfDetail = this.cmsMaterialLevelOfDetail;
        this.polymodel = this.cmsPolymodel;
      } else {
        this.materialLevelOfDetail = this.cmsMaterialLevelOfDetailMobile;
      }

      this.$refs.customModelViewer.addEventListener('load', (e) => {

        // this.$refs.customModelViewer.ar = true;
        // this.$refs.customModelViewer.shadowIntensity = 1;
        // this.$refs.customModelViewer.cameraControls = true;
        // this.$refs.customModelViewer.touchAction = 'pan-y';
        this.$nextTick(() => {
          this.modelLoading = false;
          this.$refs.customModelViewer.dismissPoster();
        });

      })

      this.$refs.viewer.addEventListener('loaded', (e) => {
        this.debugger('loaded', e.detail)
        this.thumbnailBarOptions = this.$refs.viewer.items
        this.$refs.viewer.features = {...this.$refs.viewer.features, ...this.features}
        this.zoom = this.$refs.viewer.zoom

        Object.keys(this.features).forEach(key => {
          if (this.$refs.viewer.features[key] !== undefined){
            this.$refs.viewer.features[key] = this.features[key]
          }
        })
        this.generateStaticImageUrl()
        this.modelPoster = this.getModelPosterUrl();
      })

      this.$refs.viewer.addEventListener('error', (e) => {
        this.debugger('error', e.detail)
      })

      this.$refs.viewer.addEventListener('config-change', (e) => {
        this.debugger('config-change', e.detail)
        this.generateStaticImageUrl()
        this.modelPoster = this.getModelPosterUrl();
      })

      this.$refs.viewer.addEventListener('item-change', (e) => {
        this.debugger('item-change', e.detail)
        this.generateStaticImageUrl()
      })

      this.$refs.viewer.addEventListener('frame-change', (e) => {
        this.debugger('frame-change', e.detail)
        this.frame = e.detail
        this.generateStaticImageUrl()
      })

      this.$refs.viewer.addEventListener('features-change', (e) => {
        this.debugger('features-change', e.detail)
        this.generateStaticImageUrl()
        this.modelPoster = this.getModelPosterUrl();

        this.modelLoading = true;
        this.$refs.customModelViewer.showPoster();
        this.$refs.customModelViewer.src = this.getModelUrl();

        // if(this.currentView === 'model' && this.configuration.code == 'POELITTER'){
        //   const modelUrl = this.generateModelUrl();
        //   const modelViewer = this.$refs.viewer.modelViewer

        //   if(modelViewer){
        //     console.log('updating model src')
        //     console.log(modelViewer.src)
        //     modelViewer.src = modelUrl;
        //   }
        // }

        // if(this.featureHash !== JSON.stringify(e.detail)){
        //   this.featureHash = JSON.stringify(e.detail)
        // }
      })

      this.$refs.viewer.addEventListener('fullscreen-change', (e) => {
        this.debugger('fullscreen-change', e.detail)
        this.$refs.viewer.classList.toggle('fullscreen', e.detail);
      });

      this.$refs.viewer.addEventListener('ar-ready', (e) => {
        this.debugger('ar-ready', e.detail)
      })

      this.$refs.viewer.addEventListener('ready', (e) => {
        this.debugger('ready', e.detail)
      })

      this.$watch('features', (features) => {
        this.$refs.viewer.features = features
        var urlObj = {}
        urlObj[this.productId] = features

        this.updateQueryParams(urlObj)
        this.generateStaticImageUrl()
        this.modelPoster = this.getModelPosterUrl();
        this.buildChoices(this.isResetting)
        this.isResetting = false;
      })

      this.$watch('zoom', (zoom) => {
        this.$refs.viewer.zoom = this.zoom

        if(zoom === 1){
          this.$refs.viewer.zoomStop()
        }

        this.generateStaticImageUrl()
      })

      this.$watch('polymodel', (polymodel) => {
        this.modelLoading = true;
        this.$refs.customModelViewer.src = this.getModelUrl();
      })

      this.$watch('materialLevelOfDetail', (materialLevelOfDetail) => {
        this.modelLoading = true;
        this.$refs.customModelViewer.src = this.getModelUrl();
      })

      document.addEventListener('fullscreenchange', () => {
        if(document.fullscreenElement){
          this.fullscreen = true;
        } else {
          this.fullscreen = false;
        }
      })

      this.$nextTick(() => {
        this.buildChoices()
      })
    },
    isFeatureHidden(featureCode){
      if(!this.featuresToHide.length){
        return true;
      }

      return !this.featuresToHide.find(f => f.code === featureCode)
    },
    updateFeaturesToHide(features){
      this.featuresToHide = features;
    },
    reset(){
      this.isResetting = true;
      this.features = this.initialFeatures;
      this.buildChoices(true);
    },
    buildChoices(reset = false){
      // console.log('running buildChoices')
      // console.log('availableFeatures',this.availableFeatures)
      const self = this;
      this.availableFeatures.map(feature => {
        // console.log('feature',feature)
        let choices = self.choicesJs[feature.code];
        if(!choices && self.$refs[feature.code]){
          choices = new Choices(self.$refs[feature.code], {
            allowHTML: true,
            items: [{
              value: self.features[feature.code],
              label: feature.name,
              selected: true
            }],
            callbackOnCreateTemplates: () => {
              return {
                choice(config, input, hoverText) {
                  const el = Choices.defaults.templates.choice.call(self, config, input, hoverText)

                  const swatchUrl = `http://content.cylindo.com/api/v2/${self.configuration.customer}/products/${encodeURIComponent(self.configuration.code)}/material/swatch.jpg?feature=${encodeURIComponent(feature.code)}:${input.value}`

                  if(self.featuresWithSwatches.indexOf(feature.code) !== -1){
                    el.innerHTML = `
                    <div class="inline-flex items-center gap-2">
                      <img src="${swatchUrl}" onerror="this.remove()" class="mr-1" />
                      <span>${el.innerHTML}</span>
                    </div>
                    `
                  }

                  return el
                },
                item(config, input, hoverText) {
                  const el = Choices.defaults.templates.item.call(this, config, input, hoverText)
                  // add some html to the beggining of el innerHtml
                  const swatchUrl = `http://content.cylindo.com/api/v2/${self.configuration.customer}/products/${encodeURIComponent(self.configuration.code)}/material/swatch.jpg?feature=${encodeURIComponent(feature.code)}:${input.value}`

                  if(self.featuresWithSwatches.indexOf(feature.code) !== -1){
                    el.innerHTML = `
                    <div class="flex justify-between">
                      <div class="inline-flex items-center gap-2">
                        <img src="${swatchUrl}" onerror="this.remove()" class="mr-1" />
                        <span>${el.innerHTML}</span>
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" class="w-5" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd"/>
                      </svg>
                    </div>
                    `
                  } else {
                    el.innerHTML = `
                    <div class="flex justify-between">
                      <div class="inline-flex items-center gap-2">
                        <span>${el.innerHTML}</span>
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" class="w-5" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd"/>
                      </svg>
                    </div>
                    `
                  }

                  return el
                }
              }
            },
          })

          this.$refs[feature.code].addEventListener('change', (e) => {
            this.features[feature.code] = choices.getValue(true)
            this.evaluateDependencyFeatures(feature.code)
          })

          this.choicesJs[feature.code] = choices
        }

        if(choices){
          choices.clearStore()

          let selectedFeature = feature.options.find(o => o.code == self.features[feature.code])

          if(!selectedFeature){
            selectedFeature = feature.options.find(o => o.isDefault)
          }

          choices.setChoices(feature.options.map(o => {
            return{
              value: o.code,
              label: o.name,
              disabled: reset ? false : o.disabled ?? false,
              selected: selectedFeature.code == o.code
            }
          }))
        }

      })
    },
    ensureOptionCompatibility(featureCode){
      //  here we are checking to see if the newly selected feature option puts Cylindo into an incompatible state. If it does, we need to modify one of the options to put it back into a state of compatibility
      let featuresToChange = this.disabled.filter(dependencySet => {
        let includesChangedFeature = dependencySet.find(dependencyOption => {
          return dependencyOption.code === featureCode;
        })

        let allOptionsActive = dependencySet.every(dependencyOption => {
          return dependencyOption.option === this.features[dependencyOption.code]
        })

        return includesChangedFeature && allOptionsActive;
      }).map(dependencySet => {
        return dependencySet.find(dependencyOption => dependencyOption.code !== featureCode)
      })
      if(featuresToChange.length){
        featuresToChange.forEach(feature => {
          let code = feature.code;
          let codeOptions = this.availableFeatures.find(feature => {
            return feature.code === code;
          }).options;

          let optionToUse = codeOptions.find(option => option.isDefault);

          if(!optionToUse){
            // find a suitable option that isn't on disabled list
            let invalidOptions = this.disabled.flat().filter(dependencyOption => {
              return dependencyOption.code === code
            }).map(feature => feature.option)

            optionToUse = codeOptions.find(option => !invalidOptions.includes(option));
          }
          this.features[code] = optionToUse.code;
        })
      }
    },
    processHiddenDependencyFeatures(featureCode = null){
      // if(featureCode){
      //   this.ensureOptionCompatibility(featureCode);
      // }
      // only get dependency sets where one option is 'hidden' and the other option matches a currently selected value
      // flatten the array and filter out non-hidden values
      let hiddenBasedOnSelected = this.disabled
        .filter(dependencySet => {
          return dependencySet.some(dependencyOption => {
              return this.features[dependencyOption.code] === dependencyOption.option;
            })
            &&
            dependencySet.some(dependencyOption => {
              return dependencyOption.option !== 'hidden';
            })
        })
        .flat()
        .filter(dependencyOption => {
          return dependencyOption.option == "hidden"
        });

        hiddenBasedOnSelected.forEach(dependencyOption => {
          this.features[dependencyOption.code] = dependencyOption.fallback;
        });


        if(featureCode){
          let currentHiddenFeatures = [...this.featuresToHide];
          currentHiddenFeatures
            .filter(dependencyOption => {
              return !hiddenBasedOnSelected.find(soonToHideDeps => soonToHideDeps.code === dependencyOption.code)
            })
            .forEach(dependencyOption => {
              if(dependencyOption.controlsMeshes && dependencyOption.fallback === 'NONE'){
                let featureOptions = this.availableFeatures.find(feature => feature.code === dependencyOption.code).options;
                let validOption = featureOptions.find(option => option.code !== 'NONE');
                this.features[dependencyOption.code] = validOption.code;
              }
            });
        }
        this.updateFeaturesToHide(hiddenBasedOnSelected);
    },
    processDependencyFeatures(featureCode = null){
      if(featureCode){
        this.ensureOptionCompatibility(featureCode);
      }
      let disabledBasedOnSelected =
        this.disabled
        // ignore any dependency sets where an option is 'hidden'
        .filter(dependencySet => {
          return dependencySet.every(dependencyOption => {
            return dependencyOption.option !== 'hidden';
          })
        })
        // Filter out rules which are valid based on current choices
        .filter(dependencySet => {
          return dependencySet.some(dependencyOption => {
            return this.features[dependencyOption.code] === dependencyOption.option;
          })
        })
        // get the one that is actively applied but ignore it if it 'controlsMeshes' AKA is not a cosmetic option
        // sometimes things like 'woodgrain' are marked as both 'controlsMeshes' and 'controlsMaterials' and in this case we'll treat it like it's cosmetic only
        .map(dependencySet => {
          // console.log('dependencySet', dependencySet)
          let minSortOrder = Math.min(...dependencySet.map(dependencyOption => dependencyOption.sortOrder));
          if (dependencySet.find(dependencyOption => {
            return this.features[dependencyOption.code] === dependencyOption.option
          })) {
            return dependencySet.filter(dependencyOption => {
              return this.features[dependencyOption.code] !== dependencyOption.option &&
              ( !dependencyOption.controlsMeshes ||
                (dependencyOption.controlsMaterials && dependencyOption.controlsMeshes) ||
                (dependencyOption.code.includes('SIZE') && dependencyOption.option === 'NONE') ||
                dependencyOption.sortOrder > minSortOrder
              )
            })
          }
        })
        .flat()
        .reduce((accumulator, obj, currentIndex, array) => {
          if (obj !== null) {
            const code = obj?.code
            const option = obj?.option
            const controlsMaterials = obj?.controlsMaterials
            const controlsMeshes = obj?.controlsMeshes
            const fallback = obj?.fallback
            const existingGroup = accumulator.find(group => group.code === code)
            if (existingGroup) {
              existingGroup.options.push(option)
            } else {
              accumulator.push({ code, options: [option], controlsMaterials, controlsMeshes, fallback})
            }
          } else {
            accumulator.push(null)
          }
          return accumulator
        }, [])
        // Remove empty groups
        .filter(d => d.code)
      this.availableFeatures = this.configuration.features.map((feature, index, array) => {
        const disableFeature = disabledBasedOnSelected.find(disabledFeature => disabledFeature?.code === feature.code);
        // console.log('disable feature', disableFeature);
        const updatedFeature = { ...feature };

        if (disableFeature && feature.options) {
          updatedFeature.options = feature.options.map(option => {
            const updatedOption = { ...option };
            updatedOption.disabled = disableFeature.options.includes(option.code);
            return updatedOption;
          });
        }
        // console.log('updated feature', updatedFeature)
        return updatedFeature;
      });

      this.buildChoices()
    },
    evaluateDependencyFeatures(featureCode = null){
      this.processDependencyFeatures(featureCode)
      this.processHiddenDependencyFeatures(featureCode)
    },
    debugger(name, event){
      if(this.debug){
        console.log(name, event)
      }
    },
    generateStaticImageUrl(){
      let origin = window.location.origin;
      let action = "/actions/download/helper?url="

      switch (this.currentView) {
        case 'custom-model':
          let renderedFrame = this.$refs.customModelViewer.toDataURL('image/jpeg', 0.7);
          this.saveableImage = renderedFrame;
          this.renderedFrame = renderedFrame;
          break;
        case 'referenceImage':
          this.renderedFrame = origin + action + encodeURIComponent(this.$refs.referenceImage.src);
          this.saveableImage = this.$refs.referenceImage.src;
          break;
        default:
          let renderedFrameUrl = `https://content.cylindo.com/api/v2/5121/products/${this.configuration.code}/frames/${this.frame}/${this.configuration.code}-${this.imageDownloadClicks}.jpg`

          let queryString = `?size=1920`
          queryString += `&ignoreunknownfeatures=false`

          Object.keys(this.features).forEach(featureKey => {
            queryString += `&feature=${encodeURIComponent(featureKey)}:${encodeURIComponent(this.features[featureKey])}`
          })
          this.saveableImage = renderedFrameUrl + queryString;
          this.renderedFrame = origin + action + encodeURIComponent(renderedFrameUrl) + `&featureset=${encodeURIComponent(queryString)}`;
          break;
      }
    },
    getModelUrl(){
      let baseModelUrl = `https://content.cylindo.com/api/v2/5121/products/${this.configuration.code}/AR/${this.configuration.code}.glb`

      baseModelUrl += `?polymodel=${this.polymodel}`
      baseModelUrl += `&materiallevelofdetail=${this.materialLevelOfDetail}`

      Object.keys(this.features).forEach(featureKey => {
        baseModelUrl += `&feature=${encodeURIComponent(featureKey)}:${encodeURIComponent(this.features[featureKey])}`
      })
      // console.log(baseModelUrl)
      return baseModelUrl
    },
    getModelPosterUrl(){
      let baseModelUrl = `https://content.cylindo.com/api/v2/5121/products/${this.configuration.code}/frames/1/${this.configuration.code}-${this.imageDownloadClicks}.jpeg?size=1920`

      Object.keys(this.features).forEach(featureKey => {
        baseModelUrl += `&feature=${encodeURIComponent(featureKey)}:${encodeURIComponent(this.features[featureKey])}`
      })
      return baseModelUrl
    },
    downloadModelRender(){
      let fileUrl = this.$refs.customModelViewer.toDataURL();
      const anchorElement = document.createElement('a')
      anchorElement.href = fileUrl
      anchorElement.download = `${this.productId}-${this.imageDownloadClicks}.jpg`
      anchorElement.style.display = 'none'
      document.body.appendChild(anchorElement)
      anchorElement.click()
      anchorElement.remove()
      window.URL.revokeObjectURL(fileUrl)
    },
    getRenderedFrameUrl(){
      this.generateStaticImageUrl();
      return this.renderedFrame;
    },
    show360(){
      // let availableViews = this.thumbnailBarOptions
      // let view360 = availableViews.find(v => v.type == "360")
      // this.$refs.viewer.item = {
      //   viewerItem: view360,
      //   viewerItemIndex: availableViews.indexOf(view360) ?? 0
      // }
      // this.$refs.viewer.zoom = 1

      // Alpine breaks this for some reason so lets go raw
      this.currentView = '360';
      const viewer = document.getElementById(this.$refs.viewer.id)
      let availableViews = viewer.items
      let view360 = availableViews.find((v) => v.type == "360")
      viewer.item = {
        viewerItem: view360,
        viewerItemIndex: availableViews.indexOf(view360) ?? 0
      }
    },
    // showModel(){
    //   this.currentView = 'model';
    //   let availableViews = this.$refs.viewer.items
    //   let model = availableViews.find(v => v.type == 'model')
    //   this.$refs.viewer.item = {
    //     viewerItem: model,
    //     viewerItemIndex: availableViews.indexOf(model) ?? 1
    //   }
    // },
    showModel(){
      this.currentView = 'custom-model';
    },
    showDimensionShot(){
      this.currentView = 'dimensionShot';
      let availableViews = this.$refs.viewer.items
      let viewDimensionShot = availableViews.find(v => v.type == "dimensionShot")
      this.$refs.viewer.item = {
        viewerItem: viewDimensionShot,
        viewerItemIndex: availableViews.indexOf(viewDimensionShot) ?? 2
      }
    },
    showReferenceShot(){
      this.currentView = 'referenceImage';
      let availableViews = this.$refs.viewer.items
      let viewReferenceShot = availableViews.find(v => (v.type == "custom" && v.slot == "referenceImage"))
      this.$refs.viewer.item = {
        viewerItem: viewReferenceShot,
        viewerItemIndex: availableViews.indexOf(viewReferenceShot) ?? 3
      }
    },
    showFullscreen(){
      if(this.currentView === 'custom-model'){

        this.fullscreen = true;
        if (screenfull.isEnabled) {
          let exitButton = document.querySelector('.model-viewer-exit-button');

          if(exitButton){
            exitButton.classList.add('supports-fullscreen');
          }
          screenfull.request(this.$refs.customModelViewerWrapper);
        } else {
          this.modelViewerWrapperClasses = this.$refs.customModelViewerWrapper.getAttribute('class');
          document.body.classList.add('overflow-hidden');
          document.documentElement.classList.add('overflow-hidden');
          this.$refs.customModelViewerWrapper.setAttribute('class', 'bg-white z-40 fixed w-screen h-screen inset-0')
        }

      } else {
        this.$refs.viewer.fullscreen = true
      }

    },
    hideFullscreen(){
      if(this.currentView === 'custom-model'){
        this.fullscreen = false;
        if (screenfull.isEnabled) {
          screenfull.toggle(this.$refs.customModelViewerWrapper);
        } else {
          document.body.classList.remove('overflow-hidden');
          document.documentElement.classList.remove('overflow-hidden');
          this.$refs.customModelViewerWrapper.setAttribute('class', this.modelViewerWrapperClasses)
        }

      } else {
        this.$refs.viewer.fullscreen = true
      }
    },
    zoomIn(){
      if(this.zoom < 10){
        this.zoom += 0.5
      }
    },
    zoomOut(){
      if(this.zoom > 1){
        this.zoom -= 0.5
      }
    },
    createQueryString(data){
      return Object.keys(data).map(key => {
        let val = data[key]
        if (val !== null && typeof val === 'object') val = this.createQueryString(val)
        return `${encodeURIComponent(key)}=${encodeURIComponent(`${val}`.replace(/\s/g, '_'))}`
      }).join('&')
    },
    parseQueryString(query){
      let data = {}
      query.split('&').forEach(function(part) {
        let item = part.split('=')
        let key = decodeURIComponent(item[0])
        let value = decodeURIComponent(item[1])
        if(value.indexOf(':') > -1){
          value = value.split(':')
        }
        data[key] = value
      })
      return data
    },
    updateQueryParams(features){
      let existingSearchParams = new URLSearchParams(window.location.search)
      if(existingSearchParams.has(this.productId)){
        existingSearchParams.delete(this.productId)
      }
      let urlQueryString = existingSearchParams.toString() + '&' + this.createQueryString(features)
      let qp = new URLSearchParams(urlQueryString)
      history.replaceState(null, null, '?' + qp.toString() + window.location.hash)
    },
    closeBuildShareModal(){
      this.buildShareModalOpen = false;
    },
    get wishlistFields() {
      const renderedFrame = this.getRenderedFrameUrl();
      return {
        configObject: JSON.stringify(this.configuration.features.map(f => {
          return {
            name: f.name,
            code: f.code,
            value: f.options.find(o => o.code == this.wishlistOptions[f.code]).name
          }
        }).filter(f => !this.featuresToHide.find(h => h.code === f.code))),
        configurationImage: this.saveableImage.includes('https') ? this.saveableImage : this.modelPoster,
      }
    },
    get wishlistOptions() {
      if(this.features){
        return this.features;
      }
      if(this.configuration && this.availableFeatures){
        return this.availableFeatures.map(f => {
          return {
            option: f.name,
            code: f.code,
            value: f.options?.find(o => o.code == this.features[f.code]).name
          }
        }).filter(f => !this.featuresToHide.find(h => h.code === f.code))
      }
    },

  }
}