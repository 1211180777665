export const PandiwebViewer = () => {

  return {
    init(){
      window.addEventListener('message', (event) => {
        switch (event.data.type) {
            case 'custom:pdf':
            case 'cart:submit':
              this.$dispatch('open-wishlist', {
                elementId: this.entryId,
                fields: {
                  pandiwebCode: event.data.detail.configuration.code,
                  snapshotImage: event.data.detail.configuration.image,
                  pandiwebItems: JSON.stringify(event.data.detail.items),
                },
                customized: true
              })
            break;
        }
      });
    },
  }
}